<template>
  <div>
        <el-table :data="recordList.slice((currentPage-1)*pageSize,currentPage*pageSize)" size="mini">
      <el-table-column label="信息" prop="operDesc" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column label="IP地址" prop="operIp" show-overflow-tooltip></el-table-column>
      <el-table-column label="设备名称" prop="operType" show-overflow-tooltip></el-table-column>
      <el-table-column label="时间" prop="operTime" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="page_right">
      <el-pagination
        background
        layout="total,prev, pager, next ,jumper"
        :total="recordList.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getRecordApi } from "../../../api/data";
export default {
  data() {
    return {
        recordList:[],
      currentPage: 1, //当前页
      pageSize: 10 //一页显示多少条
    };
  },
  created(){
      this.initLoadRecord();
  },
  methods: {
    //查询登录记录
    initLoadRecord() {
      getRecordApi().then(res => {
        this.recordList = res.data;
      });
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
</style>