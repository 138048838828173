<template>
  <div class="personal_view">
    <div class="personal_view_left">
      <div
        :class="isSeletedMenu=='info'?'personal_view_left_btn_selected':'personal_view_left_btn'"
        @click="isSeletedMenu='info'"
      >
        <span>个人信息</span>
      </div>
      <div
        :class="isSeletedMenu=='password'?'personal_view_left_btn_selected':'personal_view_left_btn'"
        @click="isSeletedMenu='password'"
      >
        <span>登录密码</span>
      </div>
      <div
        :class="isSeletedMenu=='mobile'?'personal_view_left_btn_selected':'personal_view_left_btn'"
        @click="isSeletedMenu='mobile'"
      >
        <span>更换手机</span>
      </div>
      <div
        :class="isSeletedMenu=='record'?'personal_view_left_btn_selected':'personal_view_left_btn'"
        @click="isSeletedMenu='record'"
      >
        <span>登录记录</span>
      </div>
    </div>
    <div class="personal_view_right">
      <div class="personal_view_right_content" v-if="isSeletedMenu=='info'">
        <div class="personal_view_right_avatar">
          <el-avatar :src="userForm.headPortrait" :size="80"></el-avatar>
        </div>
        <div class="personal_view_right_form">
          <el-form :model="userForm" ref="userForm" label-width="80px" size="small">
            <el-form-item label="昵称:">
              <el-input v-model="userForm.nickName"></el-input>
            </el-form-item>
            <el-form-item label="手机号:">{{formatMobile(userForm.mobile)}}</el-form-item>
            <el-form-item label="余额:">{{userForm.balance}} 元</el-form-item>
            <el-form-item label="场所数量:">{{placesList.length}} 个</el-form-item>
            <el-form-item>
              <el-button type="primary" style="width:100px" @click="onSubmitEditNickName">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="personal_view_right_content" v-if="isSeletedMenu=='password'">
        <div class="personal_view_right_form">
          <el-form :model="editPassWord" ref="editPassWord" label-width="130px" size="small">
            <el-form-item label="账号:">{{formatMobile(userForm.mobile)}}</el-form-item>
            <el-form-item label="旧密码:">
              <el-input
                v-model="editPassWord.oldpassword"
                type="password"
                show-password
                placeholder="请输入旧密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码:">
              <el-input
                v-model="editPassWord.newpassword"
                type="password"
                show-password
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认新密码:">
              <el-input
                v-model="editPassWord.confirmPassWord"
                type="password"
                show-password
                placeholder="请输入确认新密码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width:100px" @click="onSubmitEditPassWord">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="personal_view_right_content" v-if="isSeletedMenu=='mobile'">
        <div class="personal_view_right_form">
          <div style="text-align:center;height:50px">将发送短信{{formatMobile(userForm.mobile)}}的手机上</div>
          <el-form
            :model="newUser"
            ref="newUser"
            label-width="120px"
            size="small"
            style="padding:20px 80px"
          >
            <div v-if="step=='1'">
              <el-form-item label="账号:">{{formatMobile(userForm.mobile)}}</el-form-item>
              <el-form-item label="验证码:">
                <el-input v-model="oldMobileCode" placeholder="请输入您的验证码">
                  <template slot="append">
                    <el-button
                      style="color:#5e72f9;width:110px"
                      @click="getOldMobileCode"
                    >{{oldMobileBtnText}}</el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width:100px" @click="nextStep">下一步</el-button>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item label="新的手机号:">
                <el-input v-model="newUser.mobile" maxlength="11" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="验证码:">
                <el-input v-model="newUser.code" placeholder="请输入您的验证码">
                  <template slot="append">
                    <el-button
                      style="color:#5e72f9;width:110px"
                      @click="getNewMobileCode"
                    >{{newMobileBtnText}}</el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width:100px" @click="onSubmitEditMobile">保存</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div v-if="isSeletedMenu=='record'">
       <div style="height:420px"> 
          <el-table :data="recordList.slice((currentPage-1)*pageSize,currentPage*pageSize)" size="mini">
          <el-table-column label="登录方式" prop="operDesc" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column label="登录IP" prop="operIp" show-overflow-tooltip></el-table-column>
          <el-table-column label="设备名称" prop="operType" show-overflow-tooltip></el-table-column>
          <el-table-column label="登录时间" prop="operTime" show-overflow-tooltip></el-table-column>
        </el-table>
       </div>
        <div class="page_right">
          <el-pagination
            background
            layout="total,prev, pager, next ,jumper"
            :total="recordList.length"
            :page-size="pageSize"
            :current-page="currentPage"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  editNickNameApi,
  editPassWordApi,
  getEditMobileCodeApi,
  editEditMobileApi,
  getRecordApi
} from "../../../api/data";
import Aes from "../../../utils/aes";
export default {
  data() {
    return {
      isSeletedMenu: "info",
      editPassWord: {},
      step: 1,
      oldMobileCode: "", //旧手机输入的验证码
      newUser: {
        mobile: "",
        code: ""
      },
      timer: null,
      oldMobileBtnText: "获取验证码",
      newMobileBtnText: "获取验证码",
      validateCode: "", //返回的验证码
      recordList: [],
      currentPage: 1, //当前页
      pageSize: 10 //一页显示多少条
    };
  },
  props: {
    placesList: [],
    userForm: {}
  },
  created(){
    this.initLoadRecord();
  },
  methods: {
    //查询登录记录
    initLoadRecord(){
      getRecordApi({operModul:'登录'}).then(res=>{
        this.recordList=res.data;
      })
    },
    //发送验证码(旧手机号)
    getOldMobileCode() {
      if (this.userForm.mobile) {
        if (this.timer == null) {
          getEditMobileCodeApi({
            mobile: this.userForm.mobile,
            state: this.step
          }).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.validateCode = res.data;
            }
          });
        }
      } else {
        this.$message.warning("请输入您的手机号");
        return false;
      }

      const TIME_COUNT = 60;
      if (!this.timer) {
        this.oldMobileBtnText = TIME_COUNT;
        this.timer = setInterval(() => {
          if (
            this.oldMobileBtnText > 0 &&
            this.oldMobileBtnText <= TIME_COUNT
          ) {
            this.oldMobileBtnText--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.oldMobileBtnText = "获取验证码";
          }
        }, 1000);
      }
    },
    //输入旧手机号后完成下一步
    nextStep() {
      if (this.oldMobileCode == this.validateCode) {
        this.step = 2;
        clearInterval(this.timer);
        this.timer = null;
        this.oldMobileBtnText = "获取验证码";
      } else {
        this.$message.warning("验证码错误，请重新输入");
      }
    },
    //新手机号验证码
    getNewMobileCode() {
      if (this.newUser.mobile) {
        if (this.timer == null) {
          getEditMobileCodeApi({
            mobile: this.newUser.mobile,
            state: this.step
          }).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.validateCode = res.data;
            }
          });
        }
      } else {
        this.$message.warning("请输入您的手机号");
        return false;
      }

      const TIME_COUNT = 60;
      if (!this.timer) {
        this.newMobileBtnText = TIME_COUNT;
        this.timer = setInterval(() => {
          if (
            this.newMobileBtnText > 0 &&
            this.newMobileBtnText <= TIME_COUNT
          ) {
            this.newMobileBtnText--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
            this.newMobileBtnText = "获取验证码";
          }
        }, 1000);
      }
    },
    //保存新的手机号
    onSubmitEditMobile() {
      if (this.validateCode == this.newUser.code) {
        this.$confirm("保存完成后将退出登录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          editEditMobileApi(this.newUser).then(res => {
            if (res) {
              this.step = 1;
              this.$message.success(res.msg);
              clearInterval(this.timer);
              this.timer = null;
              this.newMobileBtnText = "获取验证码";
              this.$store.commit("setUserInfo", ""); //清空用户信息
              this.$store.commit("setAuthorization", ""); //清空登录token
              this.$router.push("/login");
            }
          });
        });
      } else {
        this.$message.warning("验证码错误，请重新输入");
      }
    },
    //修改昵称
    onSubmitEditNickName() {
      editNickNameApi({ name: this.userForm.nickName }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.$store.commit(
            "setUserInfo",
            Aes.encrypt(JSON.stringify(res.data))
          ); //保存用户信息
        }
      });
    },
    //修改密码
    onSubmitEditPassWord() {
      this.$confirm("保存完成后将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.editPassWord.mobile = this.userForm.mobile;
        editPassWordApi(this.editPassWord).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.$store.commit("setUserInfo", ""); //清空用户信息
            this.$store.commit("setAuthorization", ""); //清空登录token
            this.$router.push("/login");
          }
        });
      });
    },
    //格式化电话150****1111
    formatMobile(mobile) {
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      mobile = mobile.replace(reg, "$1****$2");
      return mobile;
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.personal_view {
  display: flex;
  padding: 20px 0;
  height: 500px;
}
.personal_view_left {
  width: 15%;
  border-right: 1px solid #d7dae2;
  cursor: pointer;
  .personal_view_left_btn {
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .personal_view_left_btn:hover {
    color: #5e72f9;
  }
  .personal_view_left_btn_selected {
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f3;
    color: #5e72f9;
  }
}

.personal_view_right {
  width: 85%;
}
.personal_view_right_content {
  display: flex;
}
.personal_view_right_avatar {
  width: 250px;
  text-align: center;
}
.personal_view_right_form {
  width: 100%;
}
</style>