<template>
  <div class="head">
    <div class="groupTitle">
      <div style="margin-right:10px">
        <img src="../../../assets/logo_home.png" height="40px" />
      </div>

      <el-divider direction="vertical" v-if="isShowGroup"></el-divider>
      <div class="centerTitle" v-show="isShowGroup">
        <p>{{placesInfo.groupName}}</p>
        <p>编号:{{placesInfo.groupId}}</p>
      </div>

      <div class="rightTitle" v-show="isShowGroup">
        <el-tooltip class="item" effect="dark" content="可切换至其它场所" placement="bottom">
          <el-button
            type="primary"
            icon="iconfont icon-qiehuan"
            circle
            plain
            size="mini"
            @click="openDialogPlaces"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="groupTitle">
      <el-popover placement="bottom" trigger="hover" style="margin-right:20px">
        <div class="user_popver_info">
          <el-avatar :src="userInfo.headPortrait" :size="50"></el-avatar>&nbsp;&nbsp;
          <strong>{{userInfo.nickName?userInfo.nickName:userInfo.mobile}}</strong>
        </div>
        <div id="user_popver">
          <el-divider></el-divider>
        </div>
        <div class="user_popver_list">
          <div class="user_popver_list_menu" @click="openPersonalCenter">
            <i class="el-icon-edit"></i>
            <span>个人中心</span>
          </div>
          <div class="user_popver_list_menu" @click="dialogPersonalLog=true">
            <i class="el-icon-share"></i>
            <span>操作日志</span>
          </div>
          <div class="user_popver_list_menu" @click="loginOut">
            <i class="el-icon-refresh-right"></i>
            <span>退出登录</span>
          </div>
        </div>

        <div slot="reference" class="groupTitle" style="cursor:pointer">
          <el-avatar :src="userInfo.headPortrait" :size="30"></el-avatar>&nbsp;
          <strong style="color: #424e67;margin-left:3px">{{userInfo.nickName?userInfo.nickName:userInfo.mobile}}</strong>
        </div>
      </el-popover>
      <el-button
        type="warning"
        round
        size="mini"
        style="width:70px;margin-right:20px;background: linear-gradient(90deg,#fff1d7,#ffd48c);font-weight: 700;border-width: 0;color: #64360d;"
        @click="toRecharge"
        v-show="isShowGroup"
      >充 值</el-button>
      <el-badge :value="notReadCount" :hidden="notReadCount==0" >
        <i class="iconfont icon-bell head_icon" @click="onSubmitNews"></i>
      </el-badge>

      <el-popover placement="bottom" trigger="hover" style="margin:0 20px">
        <div slot="reference">
          <i class="iconfont icon-kefu head_icon"></i>
        </div>
        <div>
          <h3>售前电话：</h3>
          <h3>027-89990060</h3>
          <div style="border-top:2px dashed #eee;margin:10px 0"></div>
          <h3>售前QQ：</h3>
          <h3>80056567</h3>
        </div>
      </el-popover>
      <router-link target="_blank" to="/AppDownload" class="head_link">客户端下载</router-link>&nbsp;&nbsp;&nbsp;
    </div>

    <el-dialog :visible.sync="dialogPlacesSwitch" :close-on-click-modal="false" width="60%">
      <span slot="title">切换场所</span>
      <div class="search_block">
        <el-button type="primary" size="small" @click="toPlaces">前往场所列表</el-button>
        <el-input
          v-model="search"
          placeholder="请输入场所名或编号"
          suffix-icon="el-icon-search"
          style="width:300px"
          size="small"
          @change="initGroup"
        ></el-input>
      </div>
      <el-row :gutter="0">
        <el-col
          :xl="6"
          :lg="8"
          :md="10"
          :sm="12"
          :xs="24"
          v-for="item in placesList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
          :key="item.groupId"
          class="box_col"
        >
          <div class="box-card" @click="selectedPlaces(item)">
            <div class="box-card_body">
              <div class="box_cms_qyb" v-if="item.groupType=='company'">
                <p>企业版</p>
              </div>
              <div class="box_cms_grb" v-else>
                <p>个人版</p>
              </div>
              <div class="demo-type" style="margin-top:10px;">
                <el-avatar :size="60" :src="fileUrl+item.logoUrl" :key="item.logoUrl"></el-avatar>
              </div>
              <div class="box_name">
                <h2>{{item.groupName}}</h2>
              </div>
              <div style="margin-top:10px;color:#909399">
                <span>编号:{{item.groupId}}</span>
              </div>
              <div style="margin-top:10px;color:#909399">
                <i class="el-icon-location-outline" style="font-size:18px"></i>
                <span>{{item.provinceName}} {{item.cityName}} {{item.regionName}}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :hide-on-single-page="false"
          :total="placesList.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogPersonalCenter"
      :close-on-click-modal="false"
      width="700px"
      style="padding:0;"
    >
      <span slot="title">个人中心</span>
      <div>
        <Personal :placesList="placesList" :userForm="userForm"></Personal>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogPersonalLog"
      :close-on-click-modal="false"
      width="700px"
      style="padding:0;"
    >
      <span slot="title">操作日志</span>
      <div>
        <PersonalLog></PersonalLog>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getGroupListApi, getLoginOutApi } from "../../../api/data";
import Aes from "../../../utils/aes";
import Personal from "../personal/Personal.vue";
import PersonalLog from "../personal/PersonalLog.vue";
export default {
  components: {
    Personal,
    PersonalLog
  },
  data() {
    return {
      placesList: [], //场所集合
      dialogPlacesSwitch: false,
      currentPage: 1, //当前页
      pageSize: 4, //一页显示多少条
      search: "", //搜索条件
      isReload: 1,
      dialogPersonalCenter: false, //个人中心
      dialogPersonalLog: false, //操作日志
      userForm: {}
    };
  },
  props: {
    isShowGroup: {
      type: Boolean,
      default: true
    },
    notReadCount: {
      type: Number,
      default: 0
    },
    isShowNews: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.initGroup();
  },
  computed: {
    placesInfo() {
      if (this.$store.state.user.placesInfo != "") {
        return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
      } else {
        return "";
      }
    },
    //登录账户信息
    userInfo() {
      if (this.$store.state.user.userInfo != "") {
        return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
      } else {
        return "";
      }
    },
    // 左侧菜单
    MenuList() {
      return this.$store.state.tab.isMenuList;
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    }
  },
  methods: {
    //初始化场所
    initGroup() {
      getGroupListApi({ groupIdName: this.search }).then(res => {
        console.log(res);
        if (res) this.placesList = res.data;
      });
    },

    //打开个人中心
    openPersonalCenter() {
      this.dialogPersonalCenter = true;
      this.userForm = JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    },
    //点击第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },

    //打开选择场所Dialog
    openDialogPlaces() {
      // this.initGroup();
      this.dialogPlacesSwitch = true;
    },
    //前往场所列表
    toPlaces() {
      this.$router.push("/placesList");
    },
    //切换场所
    selectedPlaces(obj) {
      if (this.placesInfo.groupId != obj.groupId) {
        this.$store.commit("setMenuSelected", ""); //设置为空默认显示第一项
        this.$store.commit("setPlacesInfo", Aes.encrypt(JSON.stringify(obj)));
        this.dialogPlacesSwitch = false;
        this.$message.success("切换场所成功");
      } else {
        this.dialogPlacesSwitch = false;
      }
    },
    //进入充值页面
    toRecharge() {
      this.$store.commit("setLinkUrl", "/recharge"); //设置为空默认显示第一项
    },
    //退出登录
    loginOut() {
      getLoginOutApi().then(res => {
        if (res) {
          this.$router.push("/login");
          this.$store.commit("setUserInfo", ""); //清空用户信息
          this.$store.commit("setAuthorization", ""); //清空登录token
        }
      });
    },
    onSubmitNews() {
      if (this.notReadCount > 0) this.$emit("callBackNews", !this.isShowNews);
    }
  }
};
</script>
<style lang='scss' scoped>
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.groupTitle {
  display: flex;
  align-items: center;
}
.centerTitle {
  min-width: 100px;
  max-width: 180px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  p {
    line-height: 20px;
  }
}
.rightTitle {
  margin-left: 10px;
}
.search_block {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.box-card {
  width: 230px;
  height: 260px;
  transition: transform 0.01s;
  -webkit-transition: all 0.01s;
  border-radius: 10px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  background: #f3f3f3;
  color: #303133;
  text-align: center;
  .box-card_body {
    padding: 20px;
    .box_cms_qyb {
      background: linear-gradient(to right, #4b90f3, #025cdf);
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 12px;
      border-radius: 0 10px 0 10px;
      p {
        color: #ffffff;
        font-size: 12px;
      }
    }
    .box_cms_grb {
      background: linear-gradient(to right, #fe9a46, #fd7f47);
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 12px;
      border-radius: 0 10px 0 10px;
      p {
        color: #ffffff;
        font-size: 12px;
      }
    }

    .box_name {
      margin-top: 20px;
      max-width: 200px;
      text-align: center;
      h2 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.box-card:hover {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  box-shadow: 0 2px 12px 0 #619aec8c;
}
.box_col {
  margin-bottom: 20px;
}

.user_popver_info {
  display: flex;
  align-items: center;
}

.user_popver_list {
  cursor: pointer;
  border-radius: 4px;
  .user_popver_list_menu {
    padding: 6px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    i {
      margin: 0 10px;
    }
  }
  .user_popver_list_menu:hover {
    background: #f3f3f3;
    color: #025cdf;
  }
}
#user_popver .el-divider--horizontal {
  margin: 12px 0;
}
.head_icon {
  font-size: 18px;
  cursor: pointer;
}
.head_icon:hover {
  color: #025cdf;
}
.head_link{
  text-decoration:none;color:#000;
}
.head_link:hover{
  color: #025cdf;
}
</style>